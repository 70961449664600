<template>
  <div class="endpointPing">
    <div class="input-group mb-4">
      <span
        class="input-group-text"
      >{{ $t('endpointPingComponent.openVpnAddress') }}</span>
      <input
        v-model="ipAddress"
        type="text"
        class="form-control"
      >
      <div class="input-group-append">
        <button
          class="btn btn-primary"
          @click="pingApi"
        >
          {{ $t('send') }}
        </button>
      </div>
    </div>
    <pre v-if="response">{{ response }}</pre>
  </div>
</template>

<script>
export default {
  name: 'EndpointPing',
  data () {
    return {
      response: null,
      ipAddress: null
    }
  },
  methods: {
    pingApi () {
      this.axios.post(`/ComponentEndpoint/Ping?ipAddress=${ this.ipAddress }`)
        .then((response) => {
          if (response == null) {
            this.response = this.$t('endpointPingComponent.noResponse');
            return;
          }
          if (response.data == null) {
            this.response = this.$t('endpointPingComponent.noResponse');
            return;
          }
          this.response = response.data;
        })
        .catch(() => {
          this.response = this.$t('endpointPingComponent.caugthError');
        });
    }
  }
}
</script>
